<template>
    <div>
        <div class="backBlack displayFlex" style="padding:0px;width:100%;text-align:center; display:none">
            <b-button class="invBTN mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/POS-RETAIL'})">{{lang.invoices}}</b-button> 
            <b-button class="salesBTN mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/pos/invoiceList'})">{{lang.sales}}</b-button> 
            <b-button class="cusBTN" @click="$router.push({path: '/pos/clientList'})">{{lang.customers}}</b-button> 
            <!-- <b-button class="mytab1 btn btn-sm btn-default m-2" style="width:130px;background:darkslateblue !important;color:#fff !important;font-size:1.1rem;border:2px solid #fff !important">{{lang.unites}} </b-button>  -->
            <b-button class="mytab1 btn btn-sm btn-default m-2 purBTN" @click="$router.push({path: '/pos/Purchase'})">{{lang.purchase}}</b-button> 
            <b-button class="mytab1 btn btn-sm btn-default m-2 expBTN" @click="$router.push({path: '/pos/Expenses'})">{{lang.expenses}}</b-button> 
            <b-button class="mytab2 btn btn-sm btn-success m-2 empBTN" @click="$router.push({path: '/pos/employees'})">{{lang.employees}}</b-button> 
            <b-button class="mytab1 btn btn-sm btn-default m-2 repBTN" @click="$router.push({path: '/pos/report/daily'})">{{lang.reports}}</b-button> 
            <!-- <b-button v-if="card._status_ != 3 && card.paid != card.ftotal" class="mytab1 btn btn-sm btn-default m-2" style="border:none !important;width:120px;background:#FFF !important;font-size:1.1rem;color:#000;" @click="printInvo(d)">{{lang.close_card}}</b-button>  -->
            <b-button class="width-120 mytab2 btn btn-sm btn-success m-2 setBTN">{{lang.settings}} </b-button> 
            <b-button class="mytab2 btn btn-sm btn-success m-2 logBTN" @click="$router.push({path: '/logout'})">{{lang.logout}} </b-button> 
            <vue-snotify></vue-snotify>
        </div>
        <div>
            <div class="mx-2" style="justify-items:center;font-size:3rem;display:flex;flex-direction:row;justify-content:space-between;align-items:stretch;z-index:100000">
                <div>
                    <div v-b-modal.extra_buttons_page  class="m-1 p-1 px-2" style="height:50px;cursor:pointer;display:flex;justify-content: space-around;align-items:center;width:120px;background:black;color:#fff;border:1px solid #000">
                        {{ lang.managment }}
                    </div>
                </div>
                <div>
                    <img :src="`/img/samcotec_logo.png`" style="width: 130px;"/> 
                </div>
                <div  @click="$router.push({path:'/logout'})"  class="m-1 p-1 px-2" style="cursor:pointer;display:flex;justify-content: space-around;align-items:center;font-size:.8rem;width:120px;background:lightblue;color:#000;border:1px solid #000">
                    <img :src="`/img/menu-with-t.png`" style="width:24px;cursor:pointer !important;margin-top:5px;" /> {{ lang.logout }}
                </div>
            </div>
            <posExtraButtons />
        </div>
    </div>
</template>

<script>
import posExtraButtons from '@/components/pos-extra-buttons-pages.vue'
export default {
    components: {posExtraButtons},
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>
<style>
.invBTN{ 
    width:120px;
    font-size:1.1rem;
    border:2px solid #fff !important;
    background:dimgray !important;
    color:#fff;
}
.salesBTN{ 
    width:120px;
    font-size:1.1rem;
    border:2px solid #fff !important;
    background:blue !important;
    color:#fff;
}
.cusBTN{ 
    border:2px solid #fff !important;
    width:130px;
    background:darkgreen !important;
    font-size:.94rem;color:#fff;
}
.purBTN{ 
    border:2px solid #fff !important;
    width:130px;
    background:darkmagenta !important;
    font-size:1.1rem;
    color:#fff;
}
.expBTN{ 
    border:2px solid #fff !important;
    width:130px;
    background:darkred !important;
    font-size:1.1rem;
    color:#fff;
}
.empBTN{
    font-size:1.1rem;
    border:2px solid #fff !important;
    background:darkslategray !important;
    width:150px;color:#fff;
}
.repBTN{
    border:2px solid #fff !important;
    width:130px;
    background:darkolivegreen !important;
    font-size:1.1rem;
    color:#fff;
}
.setBTN{
    width:120px;
    font-size:1.1rem;
    border:2px solid #fff !important;
    background:darkmagenta !important;
    color:#fff;
}
.logBTN{
    font-size:1.1rem;
    border:2px solid #fff !important;
    background:red !important;
    width:150px;color:#fff;
}
.displayFlex{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items:center;
}
.menuDisp_ar{
    /* position: fixed; */
    top:25px;
    right:0px;
    left:0px;
}
.menuDisp_en{
    /* position: fixed; */
    top:25px;
    left:0px;
    right:0px;
}
.modal-dialog-scrollable .modal-content {
    max-height: calc(100% - 50px);
    overflow: hidden;
}
@media screen and (max-width: 1024px) {
    .invBTN{
        font-size: 0.775rem;
        width:100px;
    }
    .salesBTN{
        font-size: 0.775rem;
        width:100px;
    }
    .cusBTN{
        font-size: 0.675rem;
        width:100px;
    }
    .purBTN{
        font-size: 0.775rem;
        width:100px;
    }
    .expBTN{
        font-size: 0.775rem;
        width:100px;
    }
    .empBTN{
        font-size: 0.775rem;
        width:100px;
    }
    .repBTN{
        font-size: 0.775rem;
        width:100px;
    }
    .setBTN{
        font-size: 0.775rem;
        width:100px;
    }
    .logBTN{
        font-size: 0.775rem;
        width:100px;
    }
}
</style>